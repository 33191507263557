<template>
  <div class="">
    <el-tabs v-model="optionName" @tab-click="handleClick">
      <el-tab-pane label="基本资料" name="page-investor"></el-tab-pane>
      <el-tab-pane label="投资联系" name="page-investorContact"></el-tab-pane>
    </el-tabs>


    <el-form ref="form" :model="form" label-width="80px" label-position="left">
      <el-form-item :label="$t('lang')">
        <el-radio v-model="lang" label="zh-CN">中文</el-radio>
        <el-radio v-model="lang" label="en-US">English</el-radio>
      </el-form-item>

      <el-form-item label="info">
        <el-table :data="form.info" border>
          <el-table-column type="index" label="#"></el-table-column>
          <el-table-column label="title">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.title" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_title" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column label="content">
            <template slot-scope="scope">
              <el-input v-if="lang == 'zh-CN'" v-model="scope.row.content" size="small" clearable></el-input>
              <el-input v-else v-model="scope.row.en_content" size="small" clearable></el-input>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="remove('info', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('info')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <el-form-item :label="$t('page.product.content')">
        <editor-bar v-show="lang == 'zh-CN'" ref="editor" :content.sync="form.content" :isClear="isClear" @selectImages="selectImages"  @change="change"></editor-bar>
        <editor-bar v-show="lang == 'en-US'" ref="en_editor" :content.sync="form.en_content" :isClear="isClear" @selectImages="selectImages"  @change="changeEn"></editor-bar>
      </el-form-item>

      <el-form-item>
        <el-button class="right mt20" size="mini" type="primary" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :key="multiple"
          :multiple="multiple"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>
  </div>
</template>


<script>
import {updateOption, getOption} from '@/api/os/admin';
import EditorBar from '@/components/Editor';
import selectImage from '@/components/Images';

export default {
  data() {
    return {
      optionName: 'page-investor',
      lang: 'zh-CN',

      dialogVisible: false,

      form: {
        title: '',
        en_title: '',
        desc: '',
        en_desc: '',
        code: '',
        info: [],
        content: '',
        en_content: ''
      },
      isClear: false,
      selectType: 'images',
      multiple: false,

      // activeType: 'page-investor'
    }
  },
  created() {
    this.get()
  },
  components: {
    EditorBar,
    selectImage
  },
  methods: {
    handleClick(tab, event) {
      // let a = this.activeType
      this.get()
    },
    get() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.form = res.data.data
          this.$refs.editor.setHtml(this.form.content)
          // en
          this.$refs.en_editor.setHtml(this.form.en_content)
        } else {
          this.form = {
            title: '',
            en_title: '',
            desc: '',
            en_desc: '',
            code: '',
            info: [],
            content: '',
            en_content: ''
          }
        }
      })
    },
    addAttrs(type) {
      if (!this.form[type]) {
        let data = {
          list: [],
          title: '',
          desc: ''
        }
        this.$set(this.form, type, data);
      }
      let info = {};
      return this.form[type].push(info)
    },
    remove(type, index) {
      this.form[type].splice(index, 1)
    },
    onSave() {
      updateOption({
        name: this.optionName,
        value: this.form
      }).then(res => {
        this.$message.success(this.$t('tips.success'))
      })
    },

    selectImages(type) {
      this.dialogVisible = true
      this.multiple = true
      if(type == 'thumbnail') {
        this.multiple = false
      }
      this.selectType = type
    },
    change(val) {
      this.form.content = val
    },
    changeEn(val) {
      this.form.en_content = val
    },

    getImages(data) {
      this.dialogVisible = false;

      let list = '';
      if(data && data.length > 0) {
        data.forEach(e => {
          list += `<img src="${e}">`;
        })
      }

      if(this.lang == 'en-US'){

        return this.$refs.en_editor.insertImages(list);
      }
      if(this.lang == 'zh-CN'){

        return this.$refs.editor.insertImages(list)
      }
    },
  }
}
</script>